import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AddNotification } from '@stores-actions/notification.action';
import { environment } from '@environments/environment';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store,
    private transLoco: TranslocoService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('Token')) return next.handle(request);
    return next.handle(request).pipe(
      catchError((error) => {
        // eslint-disable-next-line no-underscore-dangle
        if (
          request.url.includes(environment.baseWS) &&
          (error.status !== 401 || request.url.includes('/api/auth/')) &&
          request.headers.get('graphql-batching') !== '1' &&
          request.headers.get('skip-error-handler') !== '1'
        ) {
          if (window.navigator.onLine) {
            this.store.dispatch(
              new AddNotification(
                error?.error?.error ||
                  error?.error?.message ||
                  error?.error ||
                  error?.message ||
                  error,
                'error',
                this.transLoco.translate('bcValidationErrorKey')
              )
            );
          } else {
            this.store.dispatch(
              new AddNotification(
                this.transLoco.translate(
                  'youAreOfflineAndTheDataThatYouAreTryingToAccessWasNotPreviouslyCachedKey'
                ),
                'warning',
                this.transLoco.translate('offlineKey')
              )
            );
          }
        }
        return throwError(error);
      })
    );
  }
}
