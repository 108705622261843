import { CommonModule } from '@angular/common';
import { Component, effect, signal } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { TranslocoModule } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { MatDesignModule } from '@app/mat-design.module';
import { MainPipeModule } from '@helpers/pipes/main-pipe.module';
import { LoginOption } from '@models/login-option';
import { AuthenticationService } from '@services/authentication/authentication.service';
import {
  CustomerLogin,
  RequesterLogin,
  VendorLogin,
} from '@stores-actions/authentication.action';
import { AddNotification } from '@stores-actions/notification.action';
import { AuthState } from '@stores-states/authentication.state';
import { CompaniesGQL } from '@shared/apollo/queries/companies';
import { UserConfig } from '@api/types';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  imports: [MatDesignModule, MainPipeModule, TranslocoModule, CommonModule],
  providers: [MsalService],
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  @Select(AuthState.session) session$!: Observable<UserConfig>;

  readonly tid = signal<string | null>(null);
  readonly isAuth = signal(false);
  readonly companies = signal<string[]>([]);
  readonly token = signal<string | null>(null);
  readonly environments = signal<
    {
      id: string;
      name: string;
      customerId: string;
      bcEnvironment: string;
      odataOverride: string | null;
      soapOverride: string | null;
      default: boolean;
      defaultCompany?: string;
    }[]
  >([]);

  readonly loginForm = this.formBuilder.group({
    company: ['', Validators.required],
    user: ['', Validators.required],
    password: ['', Validators.required],
  });

  readonly loading = signal(false);

  loginLogo = '';

  protected readonly loadingChangeEffect = effect(
    () => {
      const loading = this.loading();
      loading ? this.loginForm.disable() : this.loginForm.enable();
    },
    {
      allowSignalWrites: true,
    }
  );

  isIframe = false;
  environmentId = '';
  loginType = '';

  set company(comp: string | null) {
    this.loginForm.controls.company.setValue(comp);
  }
  get company(): string | null {
    return this.loginForm.controls.company.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private companiesService: CompaniesGQL
  ) {
    this.company = this.store.selectSnapshot(AuthState.company);
    this.environmentId = this.activeRoute.snapshot.params.environmentId;
    // Take last part of the URL as login type
    this.loginType =
      this.activeRoute.snapshot.url[
        this.activeRoute.snapshot.url.length - 1
      ].path;

    this.loginLogo = `/assets/login/login-${this.loginType}.png `;

    this.getCompanies(this.environmentId);
  }

  getCompanies(environmentId: string) {
    this.companies.set([]);
    this.companiesService
      .fetch({
        environmentId,
      })
      .pipe(
        map((result) =>
          result.data.companies.items.map((companie) => ({
            ...companie,
            display_Name: companie.displayName || companie.name,
          }))
        ),
        tap((companies) => {
          this.companies.set(companies.map((companie) => companie.name));

          if (
            (!this.company && companies.length > 0) ||
            (this.company &&
              !companies
                .map((companie) => companie.name)
                .includes(this.company))
          ) {
            this.company = companies[0].name || '';
          }

          this.loginForm.updateValueAndValidity();
        })
      )
      .subscribe(this.companies);
  }

  loginInBc() {
    this.loading.set(true);

    if (!this.company) {
      this.store.dispatch(
        new AddNotification('Please select a company', 'error', 'Error')
      );
      this.loading.set(false);
      return;
    }

    let obs$;
    switch (this.loginType) {
      case 'vendor':
        obs$ = this.store.dispatch(
          new VendorLogin(
            {
              username: this.loginForm.value.user || '',
              password: this.loginForm.value.password || '',
              company: this.company,
              environmentId: this.environmentId,
            },
            LoginOption.vendor
          )
        );
        break;
      case 'requester':
        obs$ = this.store.dispatch(
          new RequesterLogin(
            {
              username: this.loginForm.value.user || '',
              password: this.loginForm.value.password || '',
              company: this.company,
              environmentId: this.environmentId,
            },
            LoginOption.requester
          )
        );
        break;
      case 'customer':
        obs$ = this.store.dispatch(
          new CustomerLogin(
            {
              username: this.loginForm.value.user || '',
              password: this.loginForm.value.password || '',
              company: this.company,
              environmentId: this.environmentId,
            },
            LoginOption.customer
          )
        );
        break;
      default:
        this.store.dispatch(
          new AddNotification('Please select a login type', 'error', 'Error')
        );
        return;
    }

    try {
      obs$
        .pipe(
          tap(() => this.authenticationService.navigateToDefaultPage()),
          finalize(() => this.loading.set(false))
        )
        .subscribe({
          error: (error) => {
            this.loading.set(false);
          },
        });
    } catch (error) {
      this.loading.set(false);
    }
  }
}
