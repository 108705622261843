import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  effect,
  signal,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { MatDesignModule } from '@app/mat-design.module';
import { MainPipeModule } from '@helpers/pipes/main-pipe.module';
import { SubscriptionsComponent } from '@helpers/subscriptions-component';
import { LoginOption } from '@models/login-option';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { AzureLogin } from '@stores-actions/authentication.action';
import { AddNotification } from '@stores-actions/notification.action';
import { AuthState } from '@stores-states/authentication.state';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
} from '@azure/msal-browser';
import { CompaniesGQL } from '@shared/apollo/queries/companies';
import { UserConfig } from '@api/types';
import { ApiService } from '@services/api.service';

export interface AuthenticateModel {
  username: string;
  password: string;
}

export const loginOptionMapping: Record<LoginOption, string> = {
  [LoginOption.customer]: 'customer',
  [LoginOption.manager]: 'manager',
  [LoginOption.technician]: 'technician',
  [LoginOption.requester]: 'requester',
  [LoginOption.vendor]: 'vendor',
  [LoginOption.default]: 'default',
};
@Component({
  standalone: true,
  imports: [MatDesignModule, MainPipeModule, TranslocoModule, CommonModule],
  providers: [MsalService],
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent
  extends SubscriptionsComponent
  implements OnInit
{
  @Select(AuthState.session) session$!: Observable<UserConfig>;

  readonly tid = signal<string | null>(null);
  readonly isAuth = signal(false);
  readonly companies = signal<string[]>([]);
  readonly token = signal<string | null>(null);
  readonly environments = signal<
    {
      id: string;
      name: string;
      customerId: string;
      bcEnvironment: string;
      odataOverride: string | null;
      soapOverride: string | null;
      default: boolean;
      defaultCompany?: string;
    }[]
  >([]);

  readonly loginForm = this.formBuilder.group({
    company: ['', Validators.required],
    impersonate: [''],
    environment: ['', Validators.required],
    selectedLogin: [LoginOption.technician, Validators.required],
  });

  readonly loading = signal(false);

  protected readonly loadingChangeEffect = effect(
    () => {
      const loading = this.loading();
      loading ? this.loginForm.disable() : this.loginForm.enable();
    },
    {
      allowSignalWrites: true,
    }
  );

  isIframe = false;
  loginOptions = Object.values(LoginOption);
  loginOptionMapping = loginOptionMapping;
  loggedIn = false;
  requestObj = {
    scopes: ['email'],
  };

  set company(comp: string | null) {
    this.loginForm.controls.company.setValue(comp);
  }
  get company(): string | null {
    return this.loginForm.controls.company.value;
  }

  set environment(comp: string | null) {
    this.loginForm.controls.environment.setValue(comp);
  }
  get environment(): string | null {
    return this.loginForm.controls.environment.value;
  }

  get impersonate(): string | null {
    return this.loginForm.controls.impersonate.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private translocoService: TranslocoService,
    private authenticationService: AuthenticationService,
    private companiesService: CompaniesGQL,
    private api: ApiService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cd: ChangeDetectorRef
  ) {
    super();

    this.company = this.store.selectSnapshot(AuthState.company);
  }

  getCompanies(environmentId: string) {
    this.companies.set([]);
    this.companiesService
      .fetch({
        environmentId,
      })
      .pipe(
        map((result) =>
          result.data.companies.items.map((companie) => ({
            ...companie,
            display_Name: companie.displayName || companie.name,
          }))
        ),
        tap((companies) => {
          this.companies.set(companies.map((companie) => companie.name));

          if (
            (!this.company && companies.length > 0) ||
            (this.company &&
              !companies
                .map((companie) => companie.name)
                .includes(this.company))
          ) {
            this.company = companies[0].name || '';
          }

          this.loginForm.updateValueAndValidity();
        })
      )
      .subscribe(this.companies);
  }

  // Azure login
  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          console.log('Logged Out');
          window.location.pathname = '/login';
        } else {
          console.log('Logged In');
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        console.log('msalBroadcastService.inProgress$ | Finished loading!');
      });

    this.loginForm.controls.environment.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) this.getCompanies(value);
      });
  }

  loginAzure() {
    this.loading.set(true);

    const obs$ = this.msalGuardConfig.authRequest
      ? this.authService.loginPopup({
          ...this.msalGuardConfig.authRequest,
        } as PopupRequest)
      : this.authService.loginPopup();
    obs$
      .pipe(
        tap((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        }),
        switchMap((tokenResponse) => {
          this.token.set(tokenResponse.idToken);
          const tid = this.parseJwt(tokenResponse.idToken).tid;
          this.tid.set(tid);

          return this.api
            .post('/auth/environments', {
              token: tokenResponse.idToken,
            })
            .pipe(
              tap((envs: any) => {
                this.environments.set(envs);
                this.environment =
                  envs.find((env: any) => env.default)?.id || envs[0].id;
                this.company =
                  envs.find((env: any) => env.default)?.defaultCompany || '';
                this.isAuth.set(true);
              }),
              finalize(() => {
                this.loading.set(false);
              })
            );
        }),
        take(1)
      )
      .subscribe({
        next: () => {
          this.cd.detectChanges();
        },

        error: (error) => {
          this.store.dispatch(
            new AddNotification(error.error, 'error', error.statusText)
          );
        },
      });
  }

  loginInBc() {
    this.loading.set(true);

    if (!this.company || !this.environment) {
      this.store.dispatch(
        new AddNotification(
          'Please select a company and environment',
          'error',
          'Error'
        )
      );
      return;
    }

    this.store
      .dispatch(
        new AzureLogin(
          this.token() || '',
          this.loginForm.value.selectedLogin || LoginOption.technician,
          this.environment || '',
          this.company,
          this.impersonate
        )
      )
      .pipe(
        tap(() => this.authenticationService.navigateToDefaultPage()),
        finalize(() => this.loading.set(false))
      )
      .subscribe({
        error: (error) => {
          this.loading.set(false);
        },
      });
  }

  // End Azure login

  filterLogin(loginOption: any): boolean {
    return ![
      LoginOption.default,
      LoginOption.vendor,
      LoginOption.customer,
    ].includes(loginOption);
  }

  private parseJwt(token: string): any {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
