import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AuthenticationService } from '@services/authentication/authentication.service';
import { Logout } from '@stores-actions/authentication.action';
import { AddNotification } from '@stores-actions/notification.action';
import { AuthState } from '@stores-states/authentication.state';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  @Select(AuthState.token) token$!: Observable<string>;

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private store: Store
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthenticationToken(request)).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          // catch issues with graphql authorization
          if (
            event.body?.errors?.some(
              (error: any) => error.extensions?.code === 'AUTH_NOT_AUTHORIZED'
            )
          ) {
            throw event;
          }
        }

        return event;
      }),
      catchError((error) => {
        if (request.url.includes('openid-configuration')) {
          return throwError(() => error);
        }

        if (request.url.includes('refresh-token')) {
          this.store.dispatch(new Logout());
          this.toastrService.info('User session expired');
          return throwError(() => error);
        }

        if (
          request.url.includes('/api/auth') ||
          (error.status !== 401 &&
            !error.body?.errors?.some(
              (_e: any) => _e.extensions?.code === 'AUTH_NOT_AUTHORIZED'
            ))
        ) {
          return throwError(() => error);
        }

        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request))),
            catchError((e: any) => {
              this.store.dispatch(
                new AddNotification(
                  'Your session has expired, pleasse login again.',
                  'warning',
                  'Authentication'
                )
              );
              return this.store.dispatch(new Logout());
            })
          );
        } else {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.authenticationService.refreshToken().pipe(
            withLatestFrom(this.token$),
            switchMap(([_, token]) => {
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(token);
              return next.handle(this.addAuthenticationToken(request));
            }),
            catchError(([subError, token]) => {
              this.refreshTokenInProgress = false;
              this.store.dispatch(new Logout());
              return throwError(subError);
            })
          );
        }
      })
    );
  }

  addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this.store.selectSnapshot(AuthState.token);

    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: 'Bearer ' + accessToken,
        'ngsw-bypass': '',
      },
    });
  }
}
