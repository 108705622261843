import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';
import { GraphQLParams, config } from '@tag/graphql';
import { Company } from '@tag/graphql';

export interface Response {
  companies: { items: Company[] };
}

@Injectable({
  providedIn: 'root',
})
export class CompaniesGQL extends Query<Response, GraphQLParams<'company'>> {
  document = gql(config.company.query);
}
