import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  effect,
  signal,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { finalize, map, takeUntil, tap } from 'rxjs/operators';

import { MatDesignModule } from '@app/mat-design.module';
import { MainPipeModule } from '@helpers/pipes/main-pipe.module';
import { SubscriptionsComponent } from '@helpers/subscriptions-component';
import { LoginOption } from '@models/login-option';
import { AuthenticationService } from '@services/authentication/authentication.service';
import {
  AzureLogin,
  CustomerLogin,
  Login,
  RequesterLogin,
} from '@stores-actions/authentication.action';
import { AddNotification } from '@stores-actions/notification.action';
import { AuthState } from '@stores-states/authentication.state';
import { CompaniesGQL } from '@shared/apollo/queries/companies';
import { UserConfig } from '@api/types';
import { ApiService } from '@services/api.service';

export interface AuthenticateModel {
  username: string;
  password: string;
}

export const loginOptionMapping: Record<LoginOption, string> = {
  [LoginOption.customer]: 'customer',
  [LoginOption.manager]: 'manager',
  [LoginOption.technician]: 'technician',
  [LoginOption.requester]: 'requester',
  [LoginOption.vendor]: 'vendor',
  [LoginOption.default]: 'default',
};
@Component({
  standalone: true,
  imports: [MatDesignModule, MainPipeModule, TranslocoModule, CommonModule],
  providers: [MsalService],
  selector: 'app-skip-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class SkipLoginFormComponent
  extends SubscriptionsComponent
  implements OnInit
{
  @Select(AuthState.session) session$!: Observable<UserConfig>;

  readonly tid = signal<string | null>(null);
  readonly companies = signal<string[]>([]);
  readonly environments = signal<
    {
      id: string;
      name: string;
      customerId: string;
      bcEnvironment: string;
      odataOverride: string | null;
      soapOverride: string | null;
      default: boolean;
      defaultCompany?: string;
    }[]
  >([]);

  readonly loginForm = this.formBuilder.group({
    company: ['', Validators.required],
    customer: ['', Validators.required],
    impersonate: ['', Validators.required],
    environment: ['', Validators.required],
    selectedLogin: [LoginOption.technician, Validators.required],
  });

  readonly loading = signal(false);

  protected readonly loadingChangeEffect = effect(
    () => {
      const loading = this.loading();
      loading ? this.loginForm.disable() : this.loginForm.enable();
    },
    {
      allowSignalWrites: true,
    }
  );

  isIframe = false;
  loginOptions = Object.values(LoginOption);
  loginOptionMapping = loginOptionMapping;
  loggedIn = false;
  requestObj = {
    scopes: ['user.read'],
  };

  set company(comp: string | null) {
    this.loginForm.controls.company.setValue(comp);
  }
  get company(): string | null {
    return this.loginForm.controls.company.value;
  }

  set environment(comp: string | null) {
    this.loginForm.controls.environment.setValue(comp);
  }
  get environment(): string | null {
    return this.loginForm.controls.environment.value;
  }

  get impersonate(): string | null {
    return this.loginForm.controls.impersonate.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private translocoService: TranslocoService,
    private authenticationService: AuthenticationService,
    private companiesService: CompaniesGQL,
    private api: ApiService,
    private cd: ChangeDetectorRef
  ) {
    super();

    this.company = this.store.selectSnapshot(AuthState.company);
    this.getEnvironments();
  }

  ngOnInit(): void {
    this.loginForm.controls.environment.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) this.getCompanies(value);
      });
  }

  getCompanies(environmentId: string) {
    this.companies.set([]);
    this.companiesService
      .fetch({
        environmentId,
      })
      .pipe(
        map((result) =>
          result.data.companies.items.map((companie) => ({
            ...companie,
            display_Name: companie.displayName || companie.name,
          }))
        ),
        tap((companies) => {
          this.companies.set(companies.map((companie) => companie.name));

          if (
            (!this.company && companies.length > 0) ||
            (this.company &&
              !companies
                .map((companie) => companie.name)
                .includes(this.company))
          ) {
            this.company = companies[0].name || '';
          }

          this.loginForm.updateValueAndValidity();
        })
      )
      .subscribe(this.companies);
  }

  getEnvironments() {
    this.environments.set([]);
    this.api
      .get('/auth/environments/skip-auth')
      .pipe(
        map((result: any) =>
          result.map((environment: any) => ({
            ...environment,
            display_Name: environment.displayName || environment.name,
          }))
        ),
        tap((environments) => {
          this.environments.set(environments);

          this.environment =
            environments.find((env: any) => env.default)?.id ||
            environments[0].id;
          this.company =
            environments.find((env: any) => env.default)?.defaultCompany || '';

          this.loginForm.updateValueAndValidity();
        })
      )
      .subscribe(this.environments);
  }

  loginInBc() {
    this.loading.set(true);

    if (!this.company || !this.environment) {
      this.store.dispatch(
        new AddNotification(
          'Please select a company and environment',
          'error',
          'Error'
        )
      );
      return;
    }

    this.store
      .dispatch(
        new AzureLogin(
          '',
          this.loginForm.value.selectedLogin || LoginOption.technician,
          this.environment || '',
          this.company,
          this.impersonate
        )
      )
      .pipe(
        tap(() => this.sessionNextSteps()),
        finalize(() => this.loading.set(false))
      )
      .subscribe({
        error: (error) => {
          this.loading.set(false);
        },
      });
  }

  // End Azure login

  filterLogin(loginOption: any): boolean {
    return ![
      LoginOption.default,
      LoginOption.vendor,
      LoginOption.customer,
    ].includes(loginOption);
  }

  private sessionNextSteps(): void {
    this.authenticationService.navigateToDefaultPage();
  }
}
