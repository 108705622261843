import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { LoginOption } from '@models/login-option';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { AuthState } from '@stores-states/authentication.state';

import { LoginFormComponent } from './login-form/login-form.component';
import { environment } from '@environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  imports: [LoginFormComponent],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class NewLoginComponent implements OnInit {
  portalVersion = '';


  constructor(
    private readonly store: Store,
    private activeRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService
  ) {

  }

  ngOnInit(): void {
    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      this.authenticationService.navigateToDefaultPage();
    }
    // eslint-disable-next-line no-underscore-dangle
    this.portalVersion = environment.version ?? 'DEV'; // TODO : Load from a config file
  }
}
