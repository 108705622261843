import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoadingScreenService } from '@services/loading-screen.service';

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {
  private activeRequests = 0;

  constructor(private readonly loadingScreenService: LoadingScreenService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (request.url.includes('Login') || request.url.includes('Token')) return next.handle(request);

    if (this.activeRequests === 0) {
      this.loadingScreenService.startLoading();
    }
    this.activeRequests += 1;

    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests -= 1;
        if (this.activeRequests === 0) {
          this.loadingScreenService.stopLoading();
        }
      })
    );
  }
}
