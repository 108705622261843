import { Injectable } from '@angular/core';
import type { FinishedWorkOrderLine } from '@tag/graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FinishedWorkOrderLinesGQL } from '@shared/apollo/queries/finished-work-order-line';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class FinishedWorkOrderLineStoreService {
  constructor(private finishedWorkOrderLinesGQL: FinishedWorkOrderLinesGQL) {}

  /**
   * Fetchs finished work order lines
   *
   * @param filter OData filter
   *
   * @returns finished work order lines
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchFinishedWorkOrderLines(
    company: string,
    filter?: string
  ): Observable<FinishedWorkOrderLine[]> {
    return this.finishedWorkOrderLinesGQL
      .watch({ filter })
      .valueChanges.pipe(map((res) => res.data.finishedWorkOrderLines.items));
  }

  /**
   * Fetch finished work order lines By work order no
   *
   * @param no work order no
   *
   * @returns finished work order lines
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchFinishedWorkOrderLinesByWorkOrder(
    no: string
  ): Observable<FinishedWorkOrderLine[]> {
    const filter = `Work_Order_No eq '${no}'`;
    return this.finishedWorkOrderLinesGQL
      .watch({ filter })
      .valueChanges.pipe(map((res) => res.data.finishedWorkOrderLines.items));
  }
}
