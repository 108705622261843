<form
  [formGroup]="loginForm"
  (submit)="loginInBc()"
  class="login-interactions"
  *transloco="let t">
  <!-- <h2 class="title">{{ t('signInKey') }}</h2> -->
  <div class="login-logo">
    <img [src]="loginLogo" [width]="175" alt="VSD Logo" />
  </div>

  <mat-form-field appearance="fill" class="w-100">
    <mat-label>{{ t('usernameKey') }}</mat-label>
    <input
      matInput
      required
      placeholder="Placeholder"
      name="item"
      type="text"
      formControlName="user" />
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>{{ t('passwordKey') }}</mat-label>
    <input
      matInput
      required
      placeholder="Placeholder"
      name="item"
      type="password"
      formControlName="password" />
  </mat-form-field>
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>{{ t('companyKey') }}</mat-label>
    <mat-select placeholder="Placeholder" name="item" formControlName="company">
      <mat-option *ngFor="let item of companies()" [value]="item">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button
    mat-raised-button
    class="login"
    id="iLogin"
    [disabled]="loading()"
    data-cy="cyLogin">
    {{ t('singInKey') }}
  </button>
</form>
