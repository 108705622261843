import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { inject } from '@vercel/analytics';
import { enableProdMode } from '@angular/core';
import { injectSpeedInsights } from '@vercel/speed-insights';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from '@environments/environment';

import { AppModule } from './app/app.module';

if (environment.enableTracking) {
  Sentry.init({
    dsn: 'https://7502c399bdde68173e0ed7cfabd15cc5@o1417888.ingest.sentry.io/4506785940766720',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        useCompression: true,
        networkDetailAllowUrls: [
          /^https:\/\/portal-api\.theassetguardian\.com\/api\/.*$/,
        ],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/portal-api\.theassetguardian\.com\/api\/.*$/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  inject({
    mode: environment.production ? 'production' : 'development',
  });
  injectSpeedInsights({
    framework: 'angular',
  });
}

if (environment.production) enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
