import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { LoginOption } from '@models/login-option';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { AuthState } from '@stores-states/authentication.state';

import { LoginFormComponent } from './login-form/login-form.component';
import { environment } from '@environments/environment';
import { SkipLoginFormComponent } from './skip-login-form/login-form.component';

@Component({
  standalone: true,
  imports: [LoginFormComponent, SkipLoginFormComponent],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  portalVersion = '';
  selectedProfile: LoginOption = LoginOption.technician;
  skipLogin = environment.skipLogin;

  constructor(
    private readonly store: Store,
    private readonly authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      this.authenticationService.navigateToDefaultPage();
    }
    // eslint-disable-next-line no-underscore-dangle
    this.portalVersion = environment.version ?? 'DEV'; // TODO : Load from a config file
  }
}
