import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/authentication/auth-guards/auth-guard.service';
import { CustomerGuardService } from './services/authentication/auth-guards/customer-guard.service';
import { ManagerGuardService } from './services/authentication/auth-guards/manager-guard.service';
import { RequesterGuardService } from './services/authentication/auth-guards/requester-guard.service';
import { TechnicianGuardService } from './services/authentication/auth-guards/technician-guard.service';
import { VendorGuardService } from '@services/authentication/auth-guards/vendor-guard.service';
import { CustomPreloadingService } from '@services/preload.service';
import { NewLoginComponent } from './new-login/login.component';

export const ROUTES: Routes = [
  {
    path: 'manager',
    canActivate: [AuthGuardService, ManagerGuardService],
    canDeactivate: [AuthGuardService],
    data: { preload: true, loadAfterSeconds: 5 },
    loadChildren: (): any =>
      import('./_new-portal-modules/manager/manager.module').then(
        (m) => m.ManagerModule
      ),
  },
  {
    path: 'technician',
    canActivate: [AuthGuardService, TechnicianGuardService],
    canDeactivate: [AuthGuardService],
    data: { preload: true, loadAfterSeconds: 5 },
    loadChildren: () =>
      import('./_new-portal-modules/technician/technician.module').then(
        (m) => m.TechnicianModule
      ),
  },
  {
    path: 'customer',
    canActivate: [AuthGuardService, CustomerGuardService],
    canDeactivate: [AuthGuardService],
    loadChildren: (): any =>
      import('./_new-portal-modules/customer/customer.module').then(
        (m) => m.CustomerModule
      ),
  },
  {
    path: 'requester',
    canActivate: [AuthGuardService, RequesterGuardService],
    canDeactivate: [AuthGuardService],
    loadChildren: (): any =>
      import('./_new-portal-modules/requester/requester.module').then(
        (m) => m.RequesterModule
      ),
  },
  {
    path: 'vendor',
    canActivate: [AuthGuardService, VendorGuardService],
    canDeactivate: [AuthGuardService],
    loadChildren: (): any =>
      import('./_new-portal-modules/vendor/vendor.module').then(
        (m) => m.VendorModule
      ),
  },

  { path: 'login', component: LoginComponent },
  {
    path: 'login/:environmentId/vendor',
    component: NewLoginComponent,
  },
  {
    path: 'login/:environmentId/requester',
    component: NewLoginComponent,
  },
  {
    path: 'login/:environmentId/customer',
    component: NewLoginComponent,
  },

  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
