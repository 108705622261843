import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import type { FinishedWorkOrderLine } from '@tag/graphql';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  GetFinishedWorkOrderLines,
  GetFinishedWorkOrderLinesByWorkOrder,
  GetSummaryFinishedWorkOrderLines,
  SetSelectedFinishedWorkOrderLine,
} from '@stores-actions/finished-work-order-line.action';
import { FinishedWorkOrderLineStoreService } from '@stores-services/finished-work-order-line-store.service';

import { AuthState } from './authentication.state';

export class FinishedWorkOrderLineStateModel {
  finishedWorkOrderLines: FinishedWorkOrderLine[] = [];
  selectedFinishedWorkOrderLine!: FinishedWorkOrderLine | null;
  initialized = false;
}

/**
 * Finished Work Order Lines metadata and action mappings.
 */
@State<FinishedWorkOrderLineStateModel>({
  name: 'finishedWorkOrderLine',
  defaults: {
    finishedWorkOrderLines: [],
    selectedFinishedWorkOrderLine: null,
    initialized: false,
  },
})
@Injectable()
export class FinishedWorkOrderLineState {
  constructor(
    private finishedWorkOrderLineStoreService: FinishedWorkOrderLineStoreService,
    private store: Store,
    private translocoService: TranslocoService
  ) {}

  @Selector()
  static getFinishedWorkOrderLines(
    state: FinishedWorkOrderLineStateModel
  ): FinishedWorkOrderLine[] {
    return state.finishedWorkOrderLines;
  }

  @Selector()
  static getSelectedFinishedWorkOrderLine(
    state: FinishedWorkOrderLineStateModel
  ): FinishedWorkOrderLine | null {
    return state.selectedFinishedWorkOrderLine;
  }

  @Selector()
  static getInitStatus(state: FinishedWorkOrderLineStateModel): boolean {
    return state.initialized;
  }

  @Action(GetFinishedWorkOrderLines, { cancelUncompleted: true })
  getFinishedWorkOrderLines(
    { getState, setState }: StateContext<FinishedWorkOrderLineStateModel>,
    { filter, bustCache }: GetFinishedWorkOrderLines
  ): Observable<FinishedWorkOrderLine[]> {
    const company = this.store.selectSnapshot(AuthState.company);
    let state = getState();
    if (state.initialized && !bustCache)
      return of(state.finishedWorkOrderLines);
    return this.finishedWorkOrderLineStoreService
      .fetchFinishedWorkOrderLines(company, filter)
      .pipe(
        tap((result) => {
          state = getState();
          setState({
            ...state,
            finishedWorkOrderLines: result,
            initialized: true,
          });
        })
      );
  }

  @Action(GetFinishedWorkOrderLinesByWorkOrder, { cancelUncompleted: true })
  getFinishedWorkOrderLinesByWorkOrder(
    { getState, setState }: StateContext<FinishedWorkOrderLineStateModel>,
    { no, bustCache }: GetFinishedWorkOrderLinesByWorkOrder
  ): Observable<FinishedWorkOrderLine[]> {
    const company = this.store.selectSnapshot(AuthState.company);
    let state = getState();
    let workOrderLineList = state.finishedWorkOrderLines.filter(
      (wol) => wol.workOrderNo === no
    );
    if (state.initialized && !bustCache && workOrderLineList.length > 0)
      return of(workOrderLineList);

    return this.finishedWorkOrderLineStoreService
      .fetchFinishedWorkOrderLinesByWorkOrder(no)
      .pipe(
        tap((result) => {
          state = getState();
          workOrderLineList = state.finishedWorkOrderLines.filter(
            (wol) => wol.workOrderNo !== no
          );
          workOrderLineList = [...workOrderLineList, ...result];
          setState({
            ...state,
            finishedWorkOrderLines: workOrderLineList,
            initialized: true,
          });
        })
      );
  }
  @Action(GetSummaryFinishedWorkOrderLines, { cancelUncompleted: true })
  getSummaryFinishedWorkOrderLines(
    { getState, setState }: StateContext<FinishedWorkOrderLineStateModel>,
    { filter, bustCache }: GetFinishedWorkOrderLines
  ): Observable<FinishedWorkOrderLine[]> {
    const company = this.store.selectSnapshot(AuthState.company);
    let state = getState();
    if (state.initialized && !bustCache)
      return of(state.finishedWorkOrderLines);
    return this.finishedWorkOrderLineStoreService
      .fetchFinishedWorkOrderLines(company, filter)
      .pipe(
        tap((result) => {
          state = getState();
          setState({
            ...state,
            finishedWorkOrderLines: result,
            initialized: true,
          });
        })
      );
  }

  @Action(SetSelectedFinishedWorkOrderLine)
  setSelectedFinishedWorkOrderLine(
    { getState, setState }: StateContext<FinishedWorkOrderLineStateModel>,
    { payload }: SetSelectedFinishedWorkOrderLine
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedFinishedWorkOrderLine: payload,
    });
  }
}
