<div class="wrapper">
  <div class="container">
    <div class="sub-container">
      <app-login-form></app-login-form>

      <div class="row bottomline">
        <label id="release-version">{{ portalVersion || '???' }}</label>
      </div>

      <div class="vsd-logo">
        <img
          src="/assets/login/vsd-login-logo.png"
          [width]="250"
          alt="VSD Logo" />
      </div>

      <div class="portal-logo"></div>
    </div>
  </div>
</div>
