import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { AuthState } from '@stores-states/authentication.state';

@Injectable({
  providedIn: 'root',
})
export class ManagerGuardService  {
  constructor(private readonly store: Store, public router: Router) {}

  canActivate(): boolean {
    const type = this.store.selectSnapshot(AuthState.userInfo)?.type;
    if (type && type.toLowerCase() !== 'manager') {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
