<div class="background-container"></div>
<div class="d-flex h-100 position-relative">
  <div class="white-background">
    <main class="container-fluid">
      <div class="login-panel mat-elevation-z10">
        @if (skipLogin) {
        <app-skip-login-form></app-skip-login-form>
        } @else {
        <app-login-form></app-login-form>
        }
      </div>
    </main>
  </div>
  <div class="black-background"></div>
</div>

<div class="row bottomline">
  <label id="release-version">{{ portalVersion || '???' }}</label>
</div>
