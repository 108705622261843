import { Injectable } from '@angular/core';
import type { Consumable } from '@tag/graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConsumablesGQL } from '@shared/apollo/queries/consumable';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class ConsumableStoreService {
  constructor(private consumablesGQL: ConsumablesGQL) {}

  /**
   * Fetchs consumable
   *
   * @param filter OData filter
   *
   * @returns consumable
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchConsumables(filter?: string): Observable<Consumable[]> {
    return this.consumablesGQL
      .watch({ filter })
      .valueChanges.pipe(map((result) => result.data.consumables.items));
  }
}
