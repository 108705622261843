import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { LoadingScreenService } from '@services/loading-screen.service';
@Component({
  standalone: true,
  imports: [CommonModule, LottieModule],
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  loading = false;
  loadingSubscription?: Subscription;
  lottieConfig?: Record<string, any>;

  options: AnimationOptions = {
    path: '/assets/Loading.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '65px',
    maxHeight: '65px',
    margin: '0 auto',
  };

  constructor(private readonly loadingScreenService: LoadingScreenService, private readonly changeDetectionRef: ChangeDetectorRef) {}

  @Input() set isLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(debounceTime(200)).subscribe((value) => {
      this.loading = value;
      this.changeDetectionRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) this.loadingSubscription.unsubscribe();
  }
}
