import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { Consumable } from '@tag/graphql';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GetConsumables } from '@stores-actions/consumable.action';
import { ConsumableStoreService } from '@stores-services/consumable-store.service';

export class ConsumableStateModel {
  consumables: Consumable[] = [];
}

/**
 * Consumable Lines metadata and action mappings.
 */
@State<ConsumableStateModel>({
  name: 'consumable',
  defaults: {
    consumables: [],
  },
})
@Injectable()
export class ConsumableState {
  constructor(private consumableStoreService: ConsumableStoreService) {}

  @Selector()
  static getConsumables(state: ConsumableStateModel): Consumable[] {
    return state.consumables;
  }

  @Action(GetConsumables, { cancelUncompleted: true })
  getConsumables(
    { getState, setState }: StateContext<ConsumableStateModel>,
    { filter }: GetConsumables
  ): Observable<Consumable[]> {
    return this.consumableStoreService.fetchConsumables(filter).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          consumables: result,
        });
      })
    );
  }
}
