import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { AuthState } from '@stores-states/authentication.state';

@Injectable({
  providedIn: 'root',
})
export class TechnicianGuardService  {
  constructor(public router: Router, private store: Store) {}

  canActivate(): boolean {
    const userType = this.store.selectSnapshot(AuthState.userInfo)?.type.toLowerCase();
    if (userType !== 'manager' && userType !== 'technician') {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
