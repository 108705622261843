import { Component, OnInit } from '@angular/core';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import themes from 'devextreme/ui/themes';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { FormService } from '@forms-services/form.service';
import { SubscriptionsComponent } from '@helpers/subscriptions-component';
import { LoginOption } from '@models/login-option';
import { TabHeadersService } from '@request-services/tab-headers.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Logout, SetPortalType } from '@stores-actions/authentication.action';
import { AuthState } from '@stores-states/authentication.state';

import { I18nSetLocal } from './transloco/i18n-set-local';
import { MsalService } from '@azure/msal-angular';
import { UserInfo } from '@api/types';

const envWindow: any = window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent extends SubscriptionsComponent implements OnInit {
  @Select(AuthState.portalType)
  private readonly portalType$!: Observable<LoginOption>;
  @Select(AuthState.userInfo) private readonly userInfo$!: Observable<UserInfo>;

  // isValidDesktop = true;

  constructor(
    // Disabled until service worker cache is fixed.
    // private readonly swUpdate: SwUpdate,
    private authService: MsalService,
    private i18nSetLocal: I18nSetLocal,
    private readonly actions: Actions,
    // Keep hotkeys even if not use just to init the service. Will be better implemented soon.
    private readonly authenticationService: AuthenticationService,
    private formsService: FormService,
    private tabHeadersService: TabHeadersService,
    private store: Store
  ) {
    super();
  }

  // @HostListener('window:focus', ['$event'])
  // async onFocus(event: any) {
  //   // if (!this.store.selectSnapshot(AuthState.isTa)) await this.apollo.client.reFetchObservableQueries();
  // }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();

    const newColorScheme = window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? 'material.blue.dark'
      : 'material.blue.light';
    themes.current(newColorScheme);

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        const updatedColorScheme = e.matches
          ? 'material.blue.dark'
          : 'material.blue.light';
        themes.current(updatedColorScheme);
      });

    this.i18nSetLocal.setLocalBySession();

    // Automated action to return to login when action logout is completed.
    this.actions
      .pipe(ofActionSuccessful(Logout), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.formsService.cachedData.clear();
        this.tabHeadersService.cachedItems.clear();
        this.store.dispatch(new StateResetAll(AuthState));

        window.location.reload();
      });

    // Automated action to switch portals when action change portal is completed.
    this.actions
      .pipe(
        ofActionSuccessful(SetPortalType),
        withLatestFrom(this.portalType$, this.userInfo$),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(([_, type, userInfo]) => {
        this.authenticationService.setPortalType(type, userInfo.type);
      });
  }
}
