import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';

import { NAVIGATION_ITEMS } from '@menu/menu-items';
import { LoginOption } from '@models/login-option';
import { RefreshToken } from '@stores-actions/authentication.action';
import { AuthState } from '@stores-states/authentication.state';
import { I18nSetLocal } from '@transloco/i18n-set-local';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  @Select(AuthState.refreshToken) refreshToken$!: Observable<string>;
  @Select(AuthState.token) token$!: Observable<string>;

  constructor(
    private readonly store: Store,
    private router: Router,
    private i18nSetLocal: I18nSetLocal
  ) {}

  /**
   * Navigates to default page based on the informatio nprovided in the store.
   */
  navigateToDefaultPage(): void {
    const userType = this.store.selectSnapshot(AuthState.userInfo);
    const userSelection = this.store.selectSnapshot(AuthState.portalType);
    const isAPM =
      this.store.selectSnapshot(AuthState.isTa) &&
      this.store.selectSnapshot(AuthState.isTimeSheet);

    this.i18nSetLocal.setLocalBySession();

    switch (userType?.type) {
      case 'Technician': {
        const obj = NAVIGATION_ITEMS.technician.find(
          (item) => item.code === userType.mobileProfileActions[0]
        );
        if (obj) {
          if (isAPM && obj.code === 'TP-Schedule')
            obj.routerLink = ['/technician/timesheet'];
          this.router.navigate([obj.routerLink.toString()]);
        } else this.router.navigate(['/technician/dashboard']);
        break;
      }
      case 'Manager': {
        switch (userSelection) {
          case 'manager': {
            this.router.navigate(['/manager/dashboard']);
            break;
          }
          case 'requester': {
            this.router.navigate(['/requester/dashboard']);
            break;
          }
          default: {
            const obj = NAVIGATION_ITEMS.technician.find(
              (item) => item.code === userType.mobileProfileActions[0]
            );
            if (obj) {
              if (isAPM && obj.code === 'TP-Schedule')
                obj.routerLink = ['/technician/timesheet'];
              this.router.navigate([obj.routerLink.toString()]);
            } else this.router.navigate(['/technician/dashboard']);
          }
        }
        break;
      }
      case 'Customer': {
        this.router.navigate(['/customer/equipment']);
        break;
      }
      case 'Vendor': {
        this.router.navigate(['/vendor/invoice']);
        break;
      }
      case 'Requester': {
        this.router.navigate(['/requester/equipment']);
        break;
      }
      default: {
        this.router.navigate(['/technician/dashboard']);
        break;
      }
    }
  }

  /**
   * Sets portal type while checking if the user has the required permission.
   * Will also redirect to the default page.
   *
   * @param portalType
   * @param userType
   */
  setPortalType(portalType: LoginOption, userType: string): void {
    switch (portalType) {
      case LoginOption.manager:
        if (userType.toLowerCase() === 'manager') {
          this.router.navigate(['/manager/dashboard']);
        }
        return;
      case LoginOption.technician:
        if (
          userType.toLowerCase() === 'manager' ||
          userType.toLowerCase() === 'technician'
        ) {
          this.router.navigate(['/technician/dashboard']);
        }
        return;
      case LoginOption.customer:
        if (userType.toLowerCase() === 'customer') {
          this.router.navigate(['/customer/equipment']);
        }
        return;
      case LoginOption.requester:
        if (userType.toLowerCase() === 'requester') {
          this.router.navigate(['/requester/dashboard']);
        }
        return;
    }
  }

  /**
   * Returns a new authentication token regenerated by the refresh token
   *
   * @returns Observable<any> (NGXS Store)
   */
  refreshToken(): Observable<any> {
    const refreshToken = this.store.selectSnapshot(AuthState.refreshToken);
    if (!refreshToken) return of();
    return this.store.dispatch(new RefreshToken());
  }
}
