import type { FinishedWorkOrderLine } from '@tag/graphql';
import { Operation } from 'fast-json-patch';

/**
 * Add Finished Work Order Line
 *
 * @param payload Finished Work Order Line object
 */
export class AddFinishedWorkOrderLine {
  static readonly type = '[FinishedWorkOrderLine] Add';

  constructor(public payload: FinishedWorkOrderLine) {}
}

/**
 * Get all Finished Work Order Lines
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetFinishedWorkOrderLines {
  static readonly type = '[FinishedWorkOrderLine] Get';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get all Finished Work Order Line for a Finished Work Order
 *
 * @param no Work Order NO
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetFinishedWorkOrderLinesByWorkOrder {
  static readonly type = '[FinishedWorkOrderLine] Get By WorkOrder';

  constructor(public no: string, public bustCache?: boolean) {}
}

/**
 * Get summary work order lines
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetSummaryFinishedWorkOrderLines {
  static readonly type = '[FinishedWorkOrderLine] Get Summary';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Update Finished Work Order Line, this method will dynamically update the cache
 * Finished Work Order Line list and selected Finished Work Order Line.
 *
 * @param no Finished Work Order No
 * @param line Finished Work Order Line No
 * @param patch JSON patch object used to update the Finished Work Order Line. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateFinishedWorkOrderLine {
  static readonly type = '[FinishedWorkOrderLine] Update';

  constructor(
    public no: string,
    public line: number,
    public patch: Operation[],
    public selectedItem?: FinishedWorkOrderLine
  ) {}
}

/**
 * Delete Finished Work Order Line, this method will dynamically update the cache
 * Finished Work Order Line list and set selected Finished Work Order Line to null.
 *
 * @param no Finished Work Order No
 * @param line Finished Work Order Line No
 */
export class DeleteFinishedWorkOrderLine {
  static readonly type = '[FinishedWorkOrderLine] Delete';

  constructor(public no: string, public line: number) {}
}

/**
 * Set selected Finished Work Order Line
 *
 * @param payload New Finished Work Order Line full object. Summary is not supported.
 */
export class SetSelectedFinishedWorkOrderLine {
  static readonly type = '[FinishedWorkOrderLine] Set';

  constructor(public payload: FinishedWorkOrderLine | null) {}
}
