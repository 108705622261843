import { CommonModule, DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  TranslocoDatePipe,
  provideTranslocoLocale,
} from '@ngneat/transloco-locale';
import {
  NgModule,
  enableProdMode,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloLink } from '@apollo/client/core';
import { createPersistedQueryLink } from 'apollo-angular/persisted-queries';
import { onError } from '@apollo/client/link/error';
import { sha256 } from 'crypto-hash';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { Store } from '@ngxs/store';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular/http';
import player, { LottiePlayer } from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule, ROUTES } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { StoreModule } from '@app/stores/store.module';
import { LoadingScreenComponent } from '@components/loading-screen/loading-screen.component';
import { ErrorHandlerInterceptor } from '@helpers/interceptors/error-handler.interceptor';
import { LoadingScreenInterceptor } from '@helpers/interceptors/loading.interceptor';
import { RefreshTokenInterceptor } from '@helpers/interceptors/refresh-token.interceptor';
import { RetryHandlerInterceptor } from '@helpers/interceptors/retry-handler.interceptor';
import { DateExtendedPipe } from '@helpers/pipes/date-extended.pipe';
import { HourMinutePipe } from '@helpers/pipes/hour-minute.pipe';
import { AddNotification } from '@stores-actions/notification.action';
import { TranslocoRootModule } from '@transloco/transloco-root.module';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from '../environments/environment';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { devExtremLocal } from '@transloco/i18n-set-local';

const authority = `https://login.microsoftonline.com/common`;
const clientId = `1b0d9332-d311-427e-9476-ec12333589f9`;

if (environment.production) {
  enableProdMode();
}

export const playerFactory = (): LottiePlayer => player;

const envWindow: any = window;
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId,
      authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
    'openid',
    'profile',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', 'openid', 'profile'],
      prompt: 'select_account',
    },
    loginFailedRoute: '/login',
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    LoadingScreenComponent,
    MatNativeDateModule,
    MatDatepickerModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      progressBar: true,
      preventDuplicates: true,
    }),
    MsalModule,
    TranslocoRootModule,
    LottieModule.forRoot({ player: playerFactory }),
    StoreModule,
    HttpClientModule,
    ApolloModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule.forRoot(ROUTES),
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryHandlerInterceptor,
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpBatchLink: HttpBatchLink, store: Store) {
        const linkChain = createPersistedQueryLink({ sha256 }).concat(
          httpBatchLink.create({
            batchMax: 15,
            uri: `${environment.baseWS}/graphql`,
            headers: new HttpHeaders({
              'GraphQL-Batching': '1',
            }),
          })
        );

        const errorLink = onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            const notifications = graphQLErrors.map(
              ({ message, path, extensions }) =>
                new AddNotification(
                  (extensions?.message as string) || message,
                  'error',
                  'GraphQL Error - ' + (path?.[0] || '')
                )
            );
            store.dispatch(notifications);
          }

          if (networkError) {
            // Already handled by the ErrorHandlerInterceptor
            if (networkError instanceof HttpErrorResponse) {
              const notifications = networkError.error?.errors?.map(
                (e: any) =>
                  new AddNotification(e.message, 'error', 'GraphQL Error')
              );
              store.dispatch(notifications);
            }
          }
        });

        const link = ApolloLink.from([errorLink, linkChain]);

        return {
          cache: new InMemoryCache(),
          link,
          defaultOptions: {
            watchQuery: {
              // fetchPolicy: 'cache-and-network',
              // nextFetchPolicy: 'cache-and-network',
            },
          },
        };
      },
      deps: [HttpBatchLink, Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
    DateExtendedPipe,
    HourMinutePipe,
    provideTranslocoLocale(devExtremLocal),
    TranslocoDatePipe,

    // Sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
