import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EquipmentViewComponent } from '@customer-portal/equipment-view/equipment-view.component';
import { FormService } from '@forms-services/form.service';
import { ModalService } from '@request-services/modal.service';
import { AuthState } from '@stores-states/authentication.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    public router: Router,
    private readonly store: Store,
    private modalService: ModalService,
    private formService: FormService
  ) {}

  canActivate(): boolean {
    const isAuthenticated = this.store.selectSnapshot(
      AuthState.isAuthenticated
    );
    if (!isAuthenticated) this.router.navigate(['/']);
    return isAuthenticated;
  }

  canDeactivate(
    component: EquipmentViewComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.formService.ngForm?.dirty)
      return this.modalService.confirmDialog('valdationUnsavedChangedKey').pipe(
        tap((res) => {
          if (res) this.formService.ngForm = null;
        })
      );
    else return true;
  }
}
