import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

import { CardState } from '@cards-stores/card.state';
import { environment } from '@environments/environment';

import { AreaState } from './states/area.state';
import { AuthState } from './states/authentication.state';
import { ConsumableState } from './states/consumable.state';
import { EquipmentState } from './states/equipment.state';
import { FacilityState } from './states/facility.state';
import { FeedbackState } from './states/feedback.state';
import { FinishedWorkOrderLineState } from './states/finished-work-order-line.state';
import { FinishedWorkOrderState } from './states/finished-work-order.state';
import { ItemState } from './states/item.state';
import { NotificationState } from './states/notification.state';
import { PersonnelState } from './states/personnel.state';
import { PurchaseOrderLineState } from './states/purchase-order-line.state';
import { PurchaseOrderState } from './states/purchase-order.state';
import { RequestState } from './states/request.state';
import { RequirementState } from './states/requirement.state';
import { TimeAndAttendanceState } from './states/time-and-attendance.state';
import { TimeSheetState } from './states/timesheet.state';
import { WorkOrderLineState } from './states/work-order-line.state';
import { WorkOrderState } from './states/work-order.state';

// TimeAndAttendanceState can be very large and need to be sanitize for devtools to prevent lag spike.
const actionSanitizer = (action: any): any =>
  action.type === 'FILE_DOWNLOAD_SUCCESS' && action.getSchedule ? { ...action, getSchedule: '<<LONG_BLOB>>' } : action;
const stateSanitizer = (state: any): any => (state.timeAndAttendance ? { ...state, timeAndAttendance: '<<LONG_BLOB>>' } : state);

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        WorkOrderLineState,
        WorkOrderState,
        RequestState,
        EquipmentState,
        TimeSheetState,
        NotificationState,
        ItemState,
        ConsumableState,
        PersonnelState,
        FacilityState,
        AreaState,
        FinishedWorkOrderLineState,
        FinishedWorkOrderState,
        AuthState,
        CardState,
        PurchaseOrderState,
        PurchaseOrderLineState,
        TimeAndAttendanceState,
        FeedbackState,
        RequirementState,
      ],
      {
        developmentMode: !environment.production,
        executionStrategy: NoopNgxsExecutionStrategy,
      }
    ),
    NgxsResetPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      actionSanitizer,
      stateSanitizer,
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['auth'],
    }),
  ],
})
export class StoreModule {}
